@mixin toolbar-theme($theme) {

    $foreground: map-get($theme, foreground);

    toolbar {

        .toolbar-separator {
            background: map-get($foreground, divider);
        }
    }
    
}