// -----------------------------------------------------------------------------------------------------
@use '@angular/material' as mat;
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
    500: white,
    contrast: (500: $dark-primary-text )
);

$fuse-black: (
    500: black,
    contrast: (500: $light-primary-text,
    )
);

$fuse-navy: (
    50: #ECECEE,
    100: #C5C6CB,
    200: #9EA1A9,
    300: #7D818C,
    400: #5C616F,
    500: #3C4252,
    600: #353A48,
    700: #2D323E,
    800: #262933,
    900: #1E2129,
    A100: #C5C6CB,
    A200: #9EA1A9,
    A400: #5C616F,
    A700: #2D323E,
    contrast: (50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
    fuse-white: $fuse-white,
    fuse-black: $fuse-black,
    fuse-navy: $fuse-navy
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Force the input field font sizes to 16px
$typography: mat.define-legacy-typography-config($font-family: 'Roboto, Helvetica Neue, Arial, sans-serif',
        $title: mat.define-typography-level(20px, 32px, 600),
        $body-2: mat.define-typography-level(14px, 24px, 600),
        $button: mat.define-typography-level(14px, 14px, 600),
        $input: mat.define-typography-level(16px, 1.125, 400) // line-height must be unitless !!!
    );

// Setup the typography
@include mat.all-legacy-component-typographies($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import "src/app/layout/components/chat-panel/chat-panel.theme";
@import "src/app/layout/components/toolbar/toolbar.theme";

// Define a mixin for easier access
@mixin components-theme($theme) {

    // Layout components
    @include chat-panel-theme($theme);
    @include toolbar-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($fuse-navy);
$default-accent-palette: mat.define-palette(mat.$light-blue-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$theme: mat.define-light-theme($default-primary-palette, $default-accent-palette, $default-warn-palette);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {

    // Create an Angular Material theme from the $theme map
    @include mat.all-legacy-component-themes($theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);

    // Apply the theme to the user components
    @include components-theme($theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $default-primary-palette,
        accent: $default-accent-palette,
        warn: $default-warn-palette
    );

@include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat.define-palette($fuse-navy, 600, 400, 700);
$yellow-light-theme-accent-palette: mat.define-palette(mat.$yellow-palette, 600, 400, 700);
$yellow-light-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$yellow-light-theme: mat.define-light-theme($yellow-light-theme-primary-palette, $yellow-light-theme-accent-palette, $yellow-light-theme-warn-palette);

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {

    // Generate the Angular Material theme
    @include mat.all-legacy-component-themes($yellow-light-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($yellow-light-theme);

    // Apply the theme to the user components
    @include components-theme($yellow-light-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $yellow-light-theme-primary-palette,
        accent: $yellow-light-theme-accent-palette,
        warn: $yellow-light-theme-warn-palette
    );

@include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat.define-palette(mat.$blue-palette);
$blue-gray-dark-theme-accent-palette: mat.define-palette(mat.$blue-gray-palette);
$blue-gray-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$blue-gray-dark-theme: mat.define-dark-theme($blue-gray-dark-theme-primary-palette, $blue-gray-dark-theme-accent-palette, $blue-gray-dark-theme-warn-palette);

// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {

    // Generate the Angular Material theme
    @include mat.all-legacy-component-themes($blue-gray-dark-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($blue-gray-dark-theme);

    // Apply the theme to the user components
    @include components-theme($blue-gray-dark-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $blue-gray-dark-theme-primary-palette,
        accent: $blue-gray-dark-theme-accent-palette,
        warn: $blue-gray-dark-theme-warn-palette
    );

@include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme($pink-dark-theme-primary-palette, $pink-dark-theme-accent-palette, $pink-dark-theme-warn-palette);

// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {

    // Generate the Angular Material theme
    @include mat.all-legacy-component-themes($pink-dark-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);

    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $pink-dark-theme-primary-palette,
        accent: $pink-dark-theme-accent-palette,
        warn: $pink-dark-theme-warn-palette
    );

@include fuse-color-classes($palettes);
}

body {
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

notifier-container ul {
    padding: 0;
}

body.theme-default .mat-mdc-elevation-z1 {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1) !important;
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1) !important;
    background: #fff !important;
}

.m-x-0 {
    margin-left: 0;
    margin-right: 0;
}

body.theme-default .mat-mdc-card:not([class*='mat-elevation-z']) {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1) !important;
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    background: #fff !important;
}

.mat-mdc-card .mat-mdc-card {
    box-shadow: 0 0 0 !important
}

body.theme-default .mat-mdc-input-element:disabled,
body.theme-default .mat-mdc-form-field-type-mat-native-select.mat-mdc-form-field-disabled .mat-mdc-form-field-infix::after {
    color: rgba(0, 0, 0, 0.6);
}

body.theme-default .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
    color: rgba(0, 0, 0, 0.6);
}

.btn-blue {
    background: #039be5;
}

a {
    cursor: pointer !important;
}

body.theme-default .mat-mdc-form-field .mat-mdc-form-field-label {
    top: 0 !important;
    height: inherit !important;
}

body.theme-default .mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-label,
.mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
    transform: translateY(0) !important;
}

input.mat-mdc-input-element,
select.mat-mdc-input-element,
textarea.mat-mdc-input-element {
    margin-top: 1em !important;
}

.mat-primary.mat-mdc-raised-button {
    background-color: #3c4252;
    color: #fff;
}

//===========Login CSS============

.mat-mdc-card-actions,
.mat-mdc-card-content,
.mat-mdc-card-subtitle {
    display: block;
    margin-bottom: 16px;
}

.mat-mdc-card-title {
    font-weight: 600 !important;
}

.mat-mdc-card-actions {
    margin-left: -8px !important;
    margin-right: -8px !important;
    padding: 8px 0 !important;
}

/*.mat-mdc-card-header .mat-mdc-card-title {
    margin-bottom: 12px;
}*/

.mat-mdc-card-header .mat-mdc-card-title {
    font-size: 20px;
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
    padding-bottom: 1.25em !important;
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-infix {
    padding: .4375em 0 !important;
}

.mat-mdc-card-title {
    margin-bottom: 8px;
}

.mat-mdc-card-title {
    display: block;
    margin-bottom: 8px;
}

.mat-mdc-card-title {
    font-size: 24px;
    font-weight: 600;
}

body.theme-default .mat-mdc-card {
    background: #fff;
    color: rgba(0, 0, 0, .87);
}

.mat-mdc-card {
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
}

.mat-mdc-card-content {
    display: block;
    padding: 0px !important;
}

.mat-mdc-form-field {
    font: 400 14px/1.125 Roboto, Helvetica Neue, Arial, sans-serif !important;
}

.mat-mdc-text-field-wrapper {
    padding: 0px !important;
}

.mat-form-field-label-wrapper input::placeholder {
    color: transparent !important;
    -webkit-text-fill-color: transparent !important;
    transition: none !important;
}

.mat-mdc-card-header {
    padding: 00px 16px 0 !important;
}

.mat-mdc-form-field-infix {
    padding: .5em 0 !important;
}

//===========Login CSS============

//===========Dashboard CSS============

body.theme-default .mat-fab.mat-primary,
body.theme-default .mat-flat-button.mat-primary,
body.theme-default .mat-mini-fab.mat-primary,
body.theme-default .mat-mdc-raised-button.mat-primary {
    background-color: #3c4252 !important;
}


.mat-mdc-button,
.mat-mdc-fab,
.mat-mdc-flat-button,
.mat-mdc-icon-button,
.mat-mdc-mini-fab,
.mat-mdc-raised-button,
.mat-mdc-stroked-button {
    font-family: Roboto, Helvetica Neue, Arial, sans-serif;
    font-size: 14px !important;
    font-weight: 600 !important;
}

body.theme-default .mat-mdc-fab.mat-accent,
body.theme-default .mat-mdc-fab.mat-primary,
body.theme-default .mat-mdc-fab.mat-warn,
body.theme-default .mat-mdc-flat-button.mat-accent,
body.theme-default .mat-mdc-flat-button.mat-primary,
body.theme-default .mat-mdc-flat-button.mat-warn,
body.theme-default .mat-mdc-mini-fab.mat-accent,
body.theme-default .mat-mdc-mini-fab.mat-primary,
body.theme-default .mat-mdc-mini-fab.mat-warn,
body.theme-default .mat-mdc-raised-button.mat-accent,
body.theme-default .mat-mdc-raised-button.mat-primary,
body.theme-default .mat-mdc-raised-button.mat-warn {
    color: #fff !important;
}

body.theme-default .mat-elevation-z1 {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1) !important;
    background: #fff !important;
}

//===========Dashboard CSS End============

//===========properties CSS End============

.mat-form-field-label-wrapper {
    top: -.84375em !important;
    padding-top: .84375em !important;
}

td.mat-mdc-cell:first-of-type,
td.mat-mdc-footer-cell:first-of-type,
th.mat-mdc-header-cell:first-of-type {
    padding-left: 24px !important;
}

body.theme-default .mat-mdc-paginator {
    background: #fff !important;
}

tr.mat-mdc-footer-row,
tr.mdc-mat-row {
    height: 48px !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: #707070 !important;
}

body.theme-default .mat-paginator,
body.theme-default .mat-mdc-paginator-page-size .mat-mdc-select-trigger {
    color: rgba(0, 0, 0, .54) !important;
}

.mat-mdc-paginator,
.mat-mdc-paginator-page-size .mat-mdc-select-trigger {
    font-family: Roboto, Helvetica Neue, Arial, sans-serif;
    font-size: 12px !important;
}

.mat-mdc-paginator-icon {
    width: 28px !important;
    fill: currentColor !important;
}

.mdc-notched-outline__leading {
    border-left: 0px solid !important;
    border-top: 0px solid !important;
    border-radius: 0px !important;
    border-color: rgba(0, 0, 0, .54) !important
}

.mdc-notched-outline__trailing {
    border-top: 0px solid !important;
    border-right: 0px solid !important;
    border-radius: 0px !important;
    border-color: rgba(0, 0, 0, .54) !important;
}

body.theme-default .mat-mdc-header-cell {
    color: rgba(0, 0, 0, .54) !important;
}

.mdc-data-table__row {
    height: 48px !important;
}

.mdc-data-table__row:last-child>.mdc-data-table__cell {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

body.theme-default .mat-mdc-button.mat-accent[disabled],
body.theme-default .mat-mdc-button.mat-primary[disabled],
body.theme-default .mat-mdc-button.mat-warn[disabled],
body.theme-default .mat-mdc-button[disabled][disabled],
body.theme-default .mat-mdc-icon-button.mat-accent[disabled],
body.theme-default .mat-mdc-icon-button.mat-primary[disabled],
body.theme-default .mat-mdc-icon-button.mat-warn[disabled],
body.theme-default .mat-mdc-icon-button[disabled][disabled],
body.theme-default .mat-mdc-stroked-button.mat-accent[disabled],
body.theme-default .mat-mdc-stroked-button.mat-primary[disabled],
body.theme-default .mat-mdc-stroked-button.mat-warn[disabled],
body.theme-default .mat-mdc-stroked-button[disabled][disabled] {
    color: rgba(0, 0, 0, .26) !important;
}

.mat-mdc-tab.mdc-tab {
    flex-grow: 0 !important;
}

body.theme-default .mat-mdc-tab-header,
body.theme-default .mat-mdc-tab-nav-bar {
    border-bottom: 1px solid rgba(0, 0, 0, .12) !important;
}

.mdc-tab {
    min-width: 160px !important;
}

table.gcListReviewTableNew.mat-mdc-table[_ngcontent-ng-c3290982570] tr[_ngcontent-ng-c3290982570] th[_ngcontent-ng-c3290982570] {
    border: 1px solid #9ea1a9 !important;
    border-right: none !important;
    background: #333 !important;
    color: #fff !important;
    text-align: center !important;
    padding: 8px 10px !important;
}

.side-list[_ngcontent-ng-c3290982570] h2[_ngcontent-ng-c3290982570],
.uploadFormLabel[_ngcontent-ng-c3290982570] {
    font-weight: bold;
    font-size: 18px;
    margin-top: 0 !important;
}

.mat-mdc-tab-label,
.mat-mdc-tab-link {
    font-family: Roboto, Helvetica Neue, Arial, sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.mat-mdc-menu-content:not(:empty) {
    padding-top: 8px;
    padding-bottom: 8px;
}

body.theme-default .mat-mdc-elevation-z4 {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}

.cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000 !important;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
}

body.theme-default .mat-mdc-menu-panel {
    background: #fff !important;
}

body.theme-default .mat-mdc-paginator,
body.theme-default .mat-mdc-paginator-page-size .mat-mdc-select-trigger {
    color: rgba(0, 0, 0, .54) !important;
}

span.dollarSign[ng-tns-c1205077789-17] {
    position: absolute !important;
    left: 10px !important;
    font-size: 16px !important;
    top: 35px !important;
}

.mat-mdc-form-field-infix {
    padding: .5em 0;
    /*border-top: .84375em solid transparent!important;*/
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #039be5 !important;
    background-color: #039be5 !important;
}

body.theme-default path.mdc-checkbox__checkmark-path {
    stroke: #fafafa !important;
}

body.theme-default .mat-mdc-table {
    background: #fff !important;
}

.mat-mdc-form-field .mat-mdc-form-field-label {
    color: #000 !important;
    font-size: 20px !important;
}

tr.mat-mdc-header-row {
    height: 56px !important;
}

body.theme-default mat-mdc-footer-row,
body.theme-default mat-mdc-header-row,
body.theme-default mat-mdc-row,
body.theme-default td.mat-mdc-cell,
body.theme-default td.mat-mdc-footer-cell,
body.theme-default th.mat-mdc-header-cell {
    border-bottom-color: rgba(0, 0, 0, .12) !important;
}

.my-form[_ngcontent-ng-c972739253] .mat-mdc-card-title[_ngcontent-ng-c972739253] {
    margin-bottom: 10px !important;
}

.mat-card-actions {
    margin-left: -8px !important;
    margin-right: -8px !important;
    padding: 8px 0 !important;
}

/*.mat-mdc-card-actions .mat-button, .mat-mdc-card-actions .mat-mdc-raised-button {
    margin: 0 8px;
}*/

fuse-sidebar.navbar-fuse-sidebar.folded:not(.unfolded) navbar navbar-vertical-style-1 .navbar-scroll-container .user .email,
fuse-sidebar.navbar-fuse-sidebar.folded:not(.unfolded) navbar navbar-vertical-style-1 .navbar-scroll-container .user .username {
    display: none !important;
}

body.theme-default .mat-mdc-select-panel:not([class*=mat-elevation-z]) {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12) !important;
}

body.theme-default .mat-mdc-select-panel {
    background: #fff !important;
}


body.theme-default .mat-mdc-select-panel .mat-mdc-option.mat-selected:not(.mat-option-multiple) {
    background: rgba(0, 0, 0, .12) !important;
}

body.theme-default .mat-option.mat-active {
    background: rgba(0, 0, 0, .04) !important;
    color: rgba(0, 0, 0, .87) !important;
}

body.theme-default .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled),
body.theme-default .mat-option:focus:not(.mat-option-disabled),
body.theme-default .mat-option:hover:not(.mat-option-disabled) {
    background: rgba(0, 0, 0, .04) !important;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background-color: rgba(0, 0, 0, .04) !important;
}

.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
    /*position: relative!important;*/
    top: 8px !important;
}


body.theme-default .mat-mdc-fab.mat-warn,
body.theme-default .mat-mdc-flat-button.mat-warn,
body.theme-default .mat-mdc-mini-fab.mat-warn,
body.theme-default .mat-mdc-raised-button.mat-warn {
    background-color: #f44336 !important;
}


form[_ngcontent-ng-c354142046] .mdc-text-field {
    position: unset !important;
    will-change: unset !important;
}

.mdc-card__actions button.mdc-button.mdc-button--raised.mat-mdc-raised-button.mat-primary.mat-mdc-button-base {
    margin-right: 10px !important;
}

fuse-sidebar.navbar-fuse-sidebar.folded:not(.unfolded) navbar navbar-vertical-style-1 .navbar-top .logo .logo-text {
    display: none !important;
}

fuse-sidebar.navbar-fuse-sidebar.folded:not(.unfolded) navbar navbar-vertical-style-1 .navbar-scroll-container .user .avatar-container .avatar {
    width: 40px;
    height: 40px;
}

.folded:not(.unfolded) .open[_ngcontent-ng-c4062912722] .children[_ngcontent-ng-c4062912722] {
    display: none !important;
}

table.gcListReviewTableNew.mat-mdc-table[_ngcontent-ng-c277801349] tr[_ngcontent-ng-c277801349] th[_ngcontent-ng-c277801349] {
    border: 1px solid #9ea1a9;
    border-right: none;
    background: #333333;
    color: #fff !important;
    text-align: center;
}

span.select-dropdown {
    position: absolute;
}

.select-dropdowns-content {
    margin-top: 20px;
}

.mat-mdc-paginator-page-size-select {
    width: 100px;
}

.mat-form-field-label-wrapper {
    width: 100% !important;
}

.Consultant-Liability .mat-form-field-label-wrapper {
    position: absolute;
}

.Consultant-Liability span.dollarSign[_ngcontent-ng-c3747107043] {
    top: 35px !important;
}

.Consultant-Liability .mdc-text-field {
    position: unset !important;
    will-change: auto !important;
}

.container.folded {
    padding-left: 64px;
}

.folded.hoverd {
    padding-left: 280px !important;
}

.mdc-text-field .mdc-floating-label {
    top: -4% !important;

}

.mdc-text-field .mdc-floating-label {

    transform: unset !important;

}

.mat-mdc-tooltip .mdc-tooltip__surface {
    background-color: #fff !important;
    border: 1px solid #ccc !important;
}

input#mat-input-6[name="license_expire"] {
    margin-top: -2px !important;
}

button.btn-blue {
    width: max-content;
}

span.labelnumbering {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    position: relative;
    line-height: 20px;
    text-align: center;
}

.borrower-property-detail mat-card {
    display: block;
}

.borrower-property-detail[_ngcontent-ng-c277801349] ul.welcomeTabwelcomeSection_ul li span {
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
    line-height: 19px !important;
}

.mat-mdc-table.gcListTable tr th {
    color: #fff !important;
}

.crossBtn {
    line-height: 18px !important;
    text-align: center !important;
    padding: 5px 8px !important;
}

table.gcListReviewTable.mat-mdc-table tr th {
    color: #fff !important;
}


// .mdc-data-table__row:last-child > .mdc-data-table__cell:last-child {
//     border-bottom: 0px solid rgba(0, 0, 0, 0.12) !important;
// }

table.gcListTable.mat-mdc-table[_ngcontent-ng-c277801349]:last-child>.mdc-data-table__cell:last-child {
    border-bottom: 0px solid rgba(0, 0, 0, 0.12) !important;
}


.RepairItemDetailsList[_ngcontent-ng-c1369805289] select.mat-mdc-input-element[_ngcontent-ng-c1369805289] {
    height: 42px !important;
}

.mainmenu_tabContent mat-icon {
    position: relative;
    top: -8px;
}

.gcContent .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab,
.gcContent .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-link {
    height: auto !important;
    background: #e2e2e2 !important;
    color: #202020 !important;
    cursor: pointer !important;
    box-sizing: border-box !important;
    opacity: 1 !important;
    font-size: 16px !important;
    text-align: unset !important;
    display: block !important;
    justify-content: center !important;
    align-items: center !important;
    float: left !important;
    white-space: nowrap !important;
    position: relative !important;
    width: 100% !important;
    clear: both !important;
}


.gcContent .mat-mdc-tab {
    margin: 1px 0 !important;
    min-width: 160px !important;
    display: block !important;
    float: left !important;
    white-space: nowrap !important;
    position: relative !important;
    width: 100% !important;
    padding: 0 !important;
    clear: both !important;
    opacity: 1 !important;
}

.gcContent .mat-mdc-tab-label .mdc-tab__content {
    display: block !important;
    justify-content: unset !important;
    align-items: unset !important;
}


.gcContent .mdc-tab .mainmenu_tabContent.allExpandTabs,
.gcContent .mdc-tab.mdc-tab--active .mainmenu_tabContent {
    background: #039be5 !important;
    color: #fff !important;
}


.gcContent .mdc-tab .mainmenu_tabContent.allExpandTabs,
.gcContent .mdc-tab .mainmenu_tabContent {
    height: auto !important;
    padding: 12px 15px !important;
    background: #e2e2e2 !important;
    color: #202020 !important;
    cursor: pointer !important;
    box-sizing: border-box !important;
    opacity: 1 !important;
    font-size: 16px !important;
    text-align: unset !important;
    display: block !important;
    justify-content: center !important;
    align-items: center !important;
    float: left !important;
    white-space: nowrap !important;
    position: relative !important;
    width: 100% !important;
    clear: both !important;
}


.mainmenu_tabContent mat-icon {
    top: 8px;
    float: left;
    font-size: 30px !important;
    font-weight: 700 !important;
    line-height: 0;
    padding-top: 8px;
    display: flex;
    justify-content: left;
    text-align: left;
    left: 10px;
    position: absolute;
}


.gcContent span.mdc-tab__content {
    display: block;
    white-space: nowrap;
    text-align: right;
    font-weight: bold;
}


.gcContent ul.submenu_tabContent {
    float: left;
    max-height: 190px;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: none;
}

.gcContent ul.submenu_tabContent {
    float: left;
    max-height: 190px;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: none;
}

.gcContent .mdc-tab.mdc-tab--active ul.submenu_tabContent {
    display: block;
}

.gcContent ul.submenu_tabContent li {
    list-style-type: none;
    float: left;
    padding: 0;
    margin: 1px 0 0;
    width: 100%;
}

.gcContent ul.submenu_tabContent li a.itemLink {
    text-decoration: none !important;
    font-size: 13px !important;
    float: left;
    padding: 5px 15px;
    margin: 0;
    width: 100%;
    text-align: right;
    white-space: normal;
    background: #8ec18e;
    color: #fff !important;
    line-height: 20px;
}


.mat-mdc-tab .mdc-tab__text-label {
    display: block !important;
}


.gcContent .progressValue {
    text-align: center;
    margin-top: -19px;
    position: absolute;
    font-size: 13px;
    width: 97%;
    color: #fff;
}

.gcContent .mat-mdc-progress-bar {
    display: block;
    height: 20px !important;
    overflow: hidden;
    position: relative;
    transition: opacity 250ms linear;
    width: 100%;
    border-radius: 15px !important;
}

.mat-progress-bar .mat-progress-bar-element,
.mat-progress-bar .mat-progress-bar-fill::after {
    height: 100%;
    position: absolute;
    width: 100%;
}

.gcContent .mat-mdc-progress-bar-fill::after {
    background-color: #039be5 !important;
}

body.theme-default .mat-mdc-progress-bar-background {
    fill: #c5c6cb;
}


.gcContent .mdc-linear-progress__bar.mdc-linear-progress__primary-bar {
    margin: 0;
}

.gcContent .mdc-linear-progress__bar-inner {
    border-color: #039be5 !important;
    border: 30px solid;
    margin: 0px !important;
    padding: 0px !important;
    fill: #039be5 !important;
    height: 20px !important;
}

.gcContent .mdc-linear-progress__buffer {
    background: #c5c6cb;
    height: 20px;
}

.addConstructionItemContainer mat-card.my-form.mat-mdc-card {
    top: 13% !important;
}

vertical-layout-1 #main>.container {
    min-height: 100% !important;
}

.mat-mdc-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-form-field-infix {
    display: block !important;
}

.reportCompent_List .mat-mdc-tab {
    justify-content: left !important;
}

.mat-expansion-panel.mat-expansion-panel.materialUnitField-table {
    margin-bottom: 10px;
}

.componentListDetails.componentList-Details .mat-mdc-input-element {
    margin-top: 7px !important;
    padding: 0px 22px !important;
}
.componentListDetails.componentList-Details .mat-mdc-input-element[placeholder="UNIT"]{
    padding-left: 0px !important;
}

.ngxLoader .backdrop {
    pointer-events: auto !important;
    z-index: 9 !important;
    width: 100%;
    height: 100%;
}

// .content {
//     z-index: -1 !important;
// }
.btnSection
{
  float: left;
  width: 100%;
  clear: both;
  margin-top: 20px;

}
.matActions {
  flex-wrap: wrap !important;
}